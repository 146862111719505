import BannerSection from "../components/BannerSection";
import bannerImage from "../assets/order-complete-banner7.jpg";
import { Button, Image } from "react-bootstrap";

const OrderComplete = () => {
  return (
    <>
      <div style={{ maxWidth: 300, margin: "auto" }}>
        <Image src={bannerImage} style={{ width: "100%" }} />
      </div>

      <section className="py-2 pb-5">
        <div className="container">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",

              padding: "20px",
              maxWidth: 500,
              margin: "auto",
            }}
          >
            <h2 style={{ fontWeight: 400 }}>已經收到您的訂單！</h2>
            <p>
              我們已傳送確認電子郵件至 xxxxx@gmail.com，您的訂單編號是
              20240818xxxx，我們將盡快為您處理訂單，如有任何問題，歡迎您與我們聯絡。
            </p>
            <Button
              style={{ marginTop: 20 }}
              variant="light"
              onClick={() => {
                window.location.href = "/member/order";
              }}
            >
              檢視訂單
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderComplete;
