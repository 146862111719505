import React from 'react';
import '../styles/Footer.css'; 
import { Link } from 'react-router-dom';
import { FaLinkedin, FaFacebookF, FaInstagram, FaYoutube, FaLine } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="Footer pt-5">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-12 mb-4">
            <h5>產品</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/micro-business-solutions-feature">微型商務解決方案</Link>
              </li>
              <li>教育自媒體解決方案</li>
              <li>整合式醫療服務解決方案</li>
              <li>智慧建築與設計應用解決方案</li>
              <li>金融實戰應用解決方案</li>
              <hr></hr>
              <li>
                <Link to="/product-updates">產品更新紀錄</Link>
              </li>
              <li>
                大鷗摩飛智能有限公司|統一編號:94205272
              </li>
            </ul>

          </div>

        
          <div className="col-md-4 col-sm-12 mb-4">
            <h5>服務與支援</h5>
            <ul className="list-unstyled">
              <li>
              <Link to="/blog">雲端數位智慧學院</Link>
              </li>
              <li>
              <Link to="/FAQ">產品Q&A</Link>
              </li>
              <li>
              <Link to="/about-us">關於我們</Link>
              </li>
              <li>
              <Link to="https://www.104.com.tw/company/1a2x6bmsw1?jobsource=index_s_ac">人才招募</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4 col-sm-12 mb-4">
            <h5>合作與聯絡</h5>
            <ul className="list-unstyled">
              <li>合作提案</li>
              <li>
              <Link to="/contact">聯絡我們</Link>
              </li>
            </ul>
            <div className="social-icons mt-3">
              <a href="https://www.linkedin.com/company/big-o-morfi/?viewAsMember=true"><FaLinkedin /></a>
              <a href="https://www.facebook.com/profile.php?id=61565571428374&mibextid=LQQJ4d"><FaFacebookF /></a>
              <a href="https://www.instagram.com/bigomorfi?igsh=cG1tdzNnYm5kbG5t"><FaInstagram /></a>
              <a href="https://www.youtube.com/@Big-O%E5%A4%A7%E9%B7%97%E6%91%A9%E9%A3%9B"><FaYoutube /></a>
              <a href="https://page.line.me/bigomorfi"><FaLine /></a>
            </div>
          </div>


          <div className="col-lg-12 mb-4 text-right">
            <div className="d-flex  flex-column flex-md-row">
            <p>Copyright © 2024 Big-O Information Intelligence Corp.</p>
            {/*<p className="ml-5">Big-O會員暨系統維運服務條款</p>*/}
            <p><Link to="/terms-Conditions">Big-O會員暨系統維運服務條款</Link></p>
            <p><Link to="/privacy-policy">隱私權聲明Privacy Policy</Link></p>
            <p>台灣</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
