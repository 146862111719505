import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/style.css'; 
import '../styles/WhyChooseUs.css'; 
import CallToAction from '../components/CallToAction';
import WhyChooseUsIamge01 from '../assets/whychooseus/whychooseus-01.png'; 
import WhyChooseUsIamge02 from '../assets/whychooseus/whychooseus-02.png'; 
import Tcloud from '../assets/whychooseus/tcloud.png'; 

const WhyChooseUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>為什麼選擇Big O | 領先的數位轉型方案</title>
        <meta name="description" content="了解 Big-O 如何協助台灣中小型企業進行數位轉型與雲端解決方案。探索我們品牌的發展歷程與成功案例。" />
        <meta name="keywords" content="Big-O, 數位轉型, 雲端解決方案, 人工智慧技術, 台灣企業, SaaS 解決方案" />
        <meta property="og:title" content="About Us - Big-O | Digital Transformation Solutions" />
        <meta property="og:description" content="Big-O provides solutions for digital transformation and cloud-based services, helping enterprises in Taiwan to succeed in the digital era." />
        <meta property="og:image" content={WhyChooseUsIamge01} />
        <meta property="og:url" content="https://www.yourwebsite.com/about-us" />
        <meta name="twitter:title" content="About Us - Big-O | Digital Transformation Solutions" />
        <meta name="twitter:description" content="Discover how Big-O assists enterprises with digital transformation through innovative cloud-based solutions and AI technology." />
        <meta name="twitter:image" content={WhyChooseUsIamge01} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <section className="whychooseus">
        <section className="py-4">
          <div className="container pt-5 pb-5">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-lg-6 order-2 order-lg-1">
                <div className="image-wrapper text-center">
                  <img src={WhyChooseUsIamge02} alt="Advantages" className="img-fluid rounded-image w-75" />
                </div>
              </div>
              <div className="col-12 col-lg-6 p-5 order-1 order-lg-2">
                <h5>為什麼選擇Big-O</h5>
                <h2 className="mb-3">懂得您渴望數位轉型<br></br>引領科技潮流的企業核心</h2>
                <p>Big-O所提供之核心產品解決方案，以獨特的角度設計，協助客戶在數位轉型及雲端應用操作層面有完整的觀點，將協助您實現雲端數位業務發展成果。</p>
                <a href="/contact" className="custom-button-bottom ml-4">聯絡我們</a>
              </div>
            </div>
          </div>
        </section>
        <section className="py-4 bg-gray">
          <div className="container pt-5 pb-5">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-lg-6 p-5 order-1 order-lg-1">
                <h2 className="mb-3">Big-O 始於AI，忠於Big Dream!!<br></br>所有事物及想像都能在Big-O達成！<br></br>Making Easy, Quickly, and Fancy!!
</h2>
                <p>Big-O 的Dream~ 讓軟體變得更聰明，讓人工智慧輔助人們更易使用。</p>
                <a href="/contact" className="custom-button-bottom ml-4">聯絡我們</a>
              </div>
              <div className="col-12 col-lg-6 order-2 order-lg-2 text-center">
                <div className="image-wrapper">
                  <img src={WhyChooseUsIamge01} alt="Advantages" className="img-fluid rounded-image w-75" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="BrandPartners py-5">
          <div className="container text-center">
            <h2>品牌績優認證</h2>
            <div className="row justify-content-center align-items-center">
              <div className="col-md-3 col-12 p-5">
                <img src={Tcloud} className="img-fluid partner-logo w-75" alt="Tcloud Logo" />
              </div>
            </div>
            <p>112年 本公司「無牆開店e秒通」產品榮獲數位發展部「引領中小微型企業數位轉型戰略攻頂計畫」遴選廠商</p>
          </div>
        </section>
      
        <CallToAction
          title="成為 Big-O 合作夥伴"
          subtitle="立即開始實現數位轉型之旅，與Big-O攜手創造未來 ！"
          buttonText="申請加入合作夥伴"
          buttonLink="/partner-ship"
        />
      </section>
    </>
  );
};

export default WhyChooseUs;
