import React from 'react';
import '../styles/LatestCasesT1.css';  
import FourthStudio from '../assets/FourthStudio.png'; 
import FourthStudioLogo from '../assets/FourthStudioLogo.png'; 
import Goalsalon from '../assets/Goalsalon.png'; 
import GoalsalonLogo from '../assets/GoalsalonLogo.png'; 
import Fuguangtoys from '../assets/Fuguangtoys.png'; 
import FuguangtoysLogo from '../assets/FuguangtoysLogo.png'; 

const LatestCases = () => {
  const cases = [
    {
      mainImage: FourthStudio,
      logoImage: FourthStudioLogo,
      title: '小真服飾坊',
      description: '既隨性慵懶又不失個性，正是我們家2004FOURTH的特色！',
      link: 'https://fourth-studio.bigomorfi.com'
    },
    {
      mainImage: Goalsalon,
      logoImage: GoalsalonLogo,
      title: '仟殿美容院',
      description: '我們存在的價值，就是要讓「顧客變美麗」！',
      link: 'https://goalsalon.bigomorfi.com'
    },
    {
      mainImage: Fuguangtoys,
      logoImage: FuguangtoysLogo,
      title: '府廣有限公司',
      description: '想扭蛋嗎？錢沒有不見，只是變成你喜歡的樣子!',
      link: 'https://fuguangtoys.bigomorfi.com'
    },
  ];

  return (
    <section className="LatestCases py-5">
      <div className="container">
        <h2 className="text-center mb-5">最新案例</h2>
        <div className="row justify-content-center">
          {cases.map((item, index) => (
            <div className="col-12 col-lg-4 mb-4" key={index}>
              <div className="card h-100">
                <div className="card-img-top-wrapper">
                  <img src={item.mainImage} alt={item.title} className="card-img-top" />
                </div>
                <div className="card-logo-wrapper text-center">
                  <img src={item.logoImage} alt={`${item.title} Logo`} className="card-logo" />
                </div>
                <div className="card-body">
                  <h5 className="card-title">{item.title}</h5>
                  <p className="card-text">{item.description}</p>
                  <a 
                    href={item.link} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="custom-button">
                    更多 →
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center mt-4">
          <a href="/leading-brand" className="btn btn-link custom-button-bottom">查看更多最新案例</a>
        </div>
      </div>
    </section>
  );
};

export default LatestCases;
